import { NextSeo } from 'next-seo'
import { serverSideTranslations } from 'next-i18next/serverSideTranslations'

import NotFound from '@/components/pages/NotFound'

import type { NextPage } from 'next'

const NotFoundPage: NextPage = () => {
  return (
    <>
      <NextSeo noindex nofollow />
      <NotFound />
    </>
  )
}

type StaticProps = {
  locale: string
}

export async function getStaticProps({ locale }: StaticProps) {
  return {
    props: {
      ...(await serverSideTranslations(locale, ['common'])),
    },
  }
}

export default NotFoundPage
