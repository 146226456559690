import styled from 'styled-components'

const ComponentStyled = styled.div`
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  background-color: ${({ theme }) => theme.colors.secondaries.opacity30};

  .global-page-wrapper {
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: center;

    .title {
      font-family: 'Playfair Display';
      font-weight: 900;
      font-size: 48px;
      line-height: 48px;
      text-align: center;
      letter-spacing: 0.01em;
      color: ${({ theme }) => theme.colors.black};
      margin-bottom: 15px;
    }

    .desc {
      font-family: 'Playfair Display';
      font-weight: 800;
      font-size: 20px;
      line-height: 24px;
      text-align: center;
      color: ${({ theme }) => theme.colors.pureBlack};
      margin-bottom: 50px;
    }
  }
`

export default ComponentStyled
