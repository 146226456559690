import { Container, Grid } from '@mui/material'
import { useTranslation } from 'react-i18next'

import Button from '@/components/atoms/Button'
import LinkRegular from '@/components/atoms/LinkRegular'
import Footer from '@/components/organisms/Footer'
import Header from '@/components/organisms/Header'
import routes from '@/config/routes'

import ComponentStyled from './styled'

const AuthError = () => {
  const { t } = useTranslation()

  return (
    <ComponentStyled>
      <Header />
      <div className='global-page-wrapper'>
        <Container maxWidth='xl'>
          <Grid container justifyContent='center'>
            <Grid item>
              <p className='title'>404</p>
              <p className='desc'>{t('Page not found')}</p>
              <LinkRegular href={routes.home}>
                <Button label={t('Home')} />
              </LinkRegular>
            </Grid>
          </Grid>
        </Container>
      </div>
      <Footer />
    </ComponentStyled>
  )
}

export default AuthError
